<template>
  <div>
    <!-- picB4322920-5273-4904-8FF3-F849C1415945.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/latest-on-the-look-of-lu-1.jpg">
    <p>
      The LEGO Universe News Network recently got together with our art director, Phillip Atencio. His creative light will shine on everything visible in LEGO Universe. See what Phillip has to say about the design process and his hopes for the first massive multiplayer online game for LEGO fans.
    </p>
    <p>
      As a special bonus feature, Phillip will also take LEGO Universe newsletter subscribers further behind the scenes. There, he’ll hand out extra scoops on the game's inspirations and how he’s handling the visual challenges of creating a virtual LEGO world.
    </p>
    <p>
      <br><b>Hi Phillip. What's your role in LEGO Universe’s creation?</b>
    </p>
    <p>
      I design the visual style of LEGO Universe and I direct the art team. I work closely with our game engineers to maintain good performance while still creating a great art look. I also act as the eyes for the project producer, I advise him in matters that concern visuals. We also have a large staff of artists for a project of this scale. I teach and mentor when needed, though some of the artists don't need teaching. I have many responsibilities.
    </p>
    <p>
      <b>What's your workday like?</b>
    </p>
    <p>
      My work time generally gets divided between art directing the visuals for the game, guiding my leads, and working closely with the concept team, as well as performing administrative tasks.
    </p>
    <p>
      A typical day starts with a meeting with the other directors. We'll discuss things like what tasks have been completed and what the upcoming challenges are. I might also meet with my art team's leaders to review what they're doing or give them new challenges. After that, I may work more closely with an individual artist.
    </p>
    <p>
      I also create prototypes and artwork that I can use to inspire the rest of the team. I generally make these pieces very early in the design process and then try to use them as examples of the direction and style we want the art to take. If I can't find an example to convey what I'm thinking, I may paint it, model it, animate it or make an After Effects comp.
    </p>
    <p>
      <b>Which parts of your job are best at? </b>
    </p>
    <p>
      Aside from creating art, leading and mentoring are my strong suits—I enjoy spotting raw talent that can be molded.
    </p>
    <!-- picDEAC4E33-D4F1-46B7-BC41-D291FD1D919C.jpg -->
    <img class="rounded img-med rightimage" src="@/assets/news-network/latest-on-the-look-of-lu-2.jpg">
    <p>
      <b>What motivates you at work?</b>
    </p>
    <p>
      I get pleasure from the performance of my team. I'm directing them, so that's a reflection on me and I want to inspire them to keep doing well.
    </p>
    <p>
      For my personal art, I just want to make the piece that I’m working on better than the one I did the day before. That’s almost impossible, but I want to have that much pride in my work.
    </p>
    <p>
      <b>Does your entire team do the same type of artwork?</b>
    </p>
    <p>
      No. The art crew is divided into different areas of specialty. We have animators who bring the Minifigs to life; they also help design systems for character behavior. We have special effects artists who create all special effects in the game, from massive explosions to simple leaves gently falling from a tree. We also have 3D artists who create the LU world. They bring the images that the concept artists design to life. These artists can build anything we need in 3D. I have a team of concept artists that helps me design the LEGO Universe. They are amazing illustrators. We have a visual effects artist who also helps me visualize ideas. This artist also helps create the user interface. We have a graphic designer who works on promotion. And finally, I have an expert LEGO modeler who constructs models from our concept art. All these people are creating the game in different ways.
    </p>
    <p>
      <b>Do you play video games?</b>
    </p>
    <p>
      Not really. The visual quality of games is still generally pretty low compared to my other inspirations, like industrial design and feature films. I will study video games that I find technically interesting, but I have other entertainment interests that get me away from the computer.
    </p>
    <p>
      <b>What challenges are unique to the creation of LEGO Universe?</b>
    </p>
    <p>
      "Shock" elements like over-the-top violence can become a crutch when you’re making video games. For LEGO Universe I’m seeking out creative ways to represent aggressive play and darker themes while still retaining LEGO values. This is an exciting challenge for me. I enjoy challenges that push my creative limits!
    </p>
    <p>
      I'd like this game to feel more like Asian games. My goal is to design a visual package that has a distinct style, has a bright clean look, and complements the look of LEGO bricks. Executing this high goal is also an exciting challenge to me. Finally, I want LU to appeal to girl players in ways that aren't condescending or stereotypical. These are things I'd like to achieve to create a game that retains LEGO values while appealing to a wide range of cultures.
    </p>
    <p>
      <b>Is your job easier or harder because the game is based on LEGO pieces?</b>
    </p>
    <p>
      Both. On one hand, a lot of my job is already done; I don't have to create a look for LEGO bricks and tons of the set designing is already finished. On the other hand, it's a challenge maintaining the game's performance while rendering LEGO bricks in real time. It’s kind of surprising, but a typical virtual LEGO brick can contain thousands of polygons. It may take hundreds of these bricks to create a simple LEGO model. That’s hundreds of thousands of polygons! To put this into perspective, I’ve worked on games where the entire main character was made up of only a few hundred polygons. You can really see the rendering challenge when you factor in that it could take hundreds of LEGO pieces to realize one model.
    </p>
    <p>
      Even with those constraints, I’ve advocated putting more bricks in the game instead of fewer. It's these LEGO elements that give the game what I call its "LEGO factor."
    </p>
    <p>
      <b>How would you describe the look of LEGO Universe?</b>
    </p>
    <p>
      I would describe it as "stylishly graphical." We're taking cues from the visual success of some of the incredible animated films released in the last few years, especially ones with a kind of retro-cool feel. I’d also like the look of LEGO Universe to include an element of nostalgia operating on a lower level that might not be immediately apparent. I design the visuals of LU to be flexible enough to evolve with changes in our game engine technology.
    </p>
    <p>
      <b>What's inspiring about working on LEGO Universe?</b>
    </p>
    <p>
      This project includes opportunities to take chances with the art. Visual designers generally don't want just to emulate another product. They might be inspired by it, but they don't want simply to emulate it. An opportunity to take chances with innovative visuals is what motivates me; it makes for a good experience and a fun project.
    </p>
    <p>
      <b>What would you like LEGO Universe players to come away with?</b>
    </p>
    <p>
      I hope they'll think the game was fun and beautiful … and I’m putting fun before beautiful. Maybe I shouldn't do that as an Art Director, but it's important to me that players enjoy the experience.
    </p>
    <p>
      My nephew: He's thirteen and he plays one of the more popular online games for kids his age. He has fun, so he keeps playing it and playing it, even though it’s a horrible-looking game!
    </p>
    <div style="clear:both;"></div>
  </div>
</template>
